import { transportation } from '@/axios'

export default {
  acceptTrip (payload) {
    return transportation().post('offers', payload)
  },
  getAllDrivers () {
    return transportation().get('drivers/company')
  },
  getAllVehicles (vehicleTypeId) {
    return transportation().get(`vehicles/company?filters[vehicleTypeId]=${vehicleTypeId}`)
  },
  assignDriverAndVichelsToTrip (payload) {
    return transportation().post('companies/assign-trip', payload)
  }
}
