<template>
  <div>
    <b-modal id="show-drivers" size="lg" centered hide-header hide-footer>
      <b-row>
        <b-col lg="6">
          <label class="">اختر سائق</label>
          <main-select
              :reduce="driver => driver.id"
              :options="drivers"
              v-model="assignDriver.driverId"
              label="name"
              placeholder="اسم السائق"
              dir="rtl"
          />
        </b-col>
        <b-col lg="6">
          <label class="">اختر شاحنه</label>
          <main-select
              :reduce="vehicle => vehicle.id"
              :options="vehiclesType"
              v-model="assignDriver.vehicleId"
              label="model"
              placeholder="اسم شاحنه"
              dir="rtl"
          />
        </b-col>
        <b-col md="12">
          <div class="text-center d-flex justify-content-center">
            <b-button variant="primary" @click="assignDriverAndVichels"> إسناد </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <dashboard-page-title :showAddBtn="false" :showFilter="true" :showSearch="true" :showExportation="true">{{$t('trips.trips')}}</dashboard-page-title>
    <main-table :fields="fields" :baseURL="VUE_APP_TRANSPORTATION_LINK" :status-url="'vehicles/status'"  :list_url="'trips/company'"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_TRANSPORTATION_LINK } from '@/config/constant'
import transportation from '@/modules/Company/trips/services/trip'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      drivers: [],
      vehiclesType: [],
      VUE_APP_TRANSPORTATION_LINK,
      fields: [
        { label: this.$t('trips.tripId'), key: 'id', class: 'text-right' },
        { label: this.$t('trips.pickUp'), key: 'startAddress', class: 'text-right' },
        { label: this.$t('trips.pickIn'), key: 'endAddress', class: 'text-right' },
        { label: this.$t('trips.capacityNumber'), key: 'noOfCamels', class: 'text-right' },
        { label: this.$t('trips.tripPrice'), key: 'price', class: 'text-right' },
        { label: this.$t('trips.date'), key: 'dateTime', class: 'text-right' },
        { label: this.$t('trips.status'), key: 'status', class: 'text-right', type: 'status' },
        {
          label: this.$t('drivers.control'),
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'view',
              icon: 'ri-eye-fill',
              color: 'primary',
              ifNavigate: true,
              routePath: 'show-trip/:id'
            },
            {
              text: 'showPopup',
              icon: 'ri-ball-pen-fill',
              color: 'info',
              actionName: 'showPopupAssign',
              actionParams: ['vehicleTypeId', 'id']
            }
          ]
        }
      ],
      assignDriver: {
        driverId: '',
        vehicleId: '',
        vehicleTypeId: '',
        tripId: ''
      }
    }
  },
  methods: {
    getDrivers () {
      transportation.getAllDrivers().then(res => {
        this.drivers = res.data.data
      })
    },
    // getVehicles () {
    //   transportation.getAllVehicles().then(res => {
    //     this.vehiclesType = res.data.data
    //   })
    // },
    showPopupAssign (data) {
      this.assignDriver.vehicleTypeId = data.vehicleTypeId
      this.assignDriver.tripId = data.id
      transportation.getAllVehicles(data.vehicleTypeId).then(res => {
        this.vehiclesType = res.data.data
        this.$bvModal.show('show-drivers')
      })
    },
    assignDriverAndVichels () {
      transportation.assignDriverAndVichelsToTrip(this.assignDriver).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('show-drivers')
      })
    }
  },
  created () {
    this.getDrivers()
    this.$root.$on('showPopupAssign', this.showPopupAssign)
  }
}
</script>
